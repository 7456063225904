import { components } from '@/styles/theme/components';
import { greyPalette, mainPalette as palette } from '@/styles/theme/palette';
import { picker } from '@/styles/theme/picker';
import { spacing } from '@/styles/theme/spacing';
import { typography } from '@/styles/theme/typography';
import { type ThemeOptions } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  spacing,
  typography,
  components,
  picker,
};

export const mainTheme: ThemeOptions = {
  ...themeOptions,
  palette,
};

export const greyTheme: ThemeOptions = {
  ...themeOptions,
  palette: greyPalette,
};
