import { atom, type WritableAtom } from 'jotai';

const isSSR = typeof window === 'undefined';

export const atomWithLocalStorage = <T>(key: string, initialValue: T): WritableAtom<T, unknown[], void> => {
  const getInitialValue = (): T => {
    if (isSSR) {
      return initialValue;
    }

    const item = localStorage.getItem(key);

    if (item !== null) {
      return JSON.parse(item);
    }

    return initialValue;
  };

  const baseAtom = atom(getInitialValue());

  return atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue = typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      localStorage.setItem(key, JSON.stringify(nextValue));
    },
  );
};
