import Loading from '@/components/common/Loading';
import { apiSdkAtom } from '@/context/atoms/ApiSdk';
import { jwtAuthMechanismAtom } from '@/context/atoms/JWTAuthMechanism';
import { publicConfigAtom } from '@/context/atoms/publicConfig';
import { userPermissionsAtom } from '@/context/atoms/UserPermissions';
import { NoSsr } from '@mui/base';
import { createStore, Provider, useAtomValue } from 'jotai';
import React, { Suspense } from 'react';

const PreloaderPublicConfig = (): null => {
  useAtomValue(publicConfigAtom);
  return null;
};

const Preloader = (): null => {
  useAtomValue(jwtAuthMechanismAtom);
  useAtomValue(apiSdkAtom);
  useAtomValue(userPermissionsAtom);
  return null;
};

export default function AtomProvider({ children }: { children: React.ReactNode }): React.JSX.Element {
  const store = createStore();
  return (
    <Provider store={store}>
      <NoSsr>
        <Suspense fallback={<Loading size={150} />}>
          <PreloaderPublicConfig />
          <Suspense fallback={<Loading size={150} />}>
            <Preloader />
            {children}
          </Suspense>
        </Suspense>
      </NoSsr>
    </Provider>
  );
}
