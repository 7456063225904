'use client';
import useDynamicTheme from '@/components/emotion/useDynamicTheme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import NextAppDirEmotionCacheProvider, { type NextAppDirEmotionCacheProviderProps } from './EmotionCache';

export default function ThemeRegistry({ children, options }: NextAppDirEmotionCacheProviderProps): React.JSX.Element {
  const { key, prepend } = options;
  const { dynamicMainTheme } = useDynamicTheme();

  return (
    <NextAppDirEmotionCacheProvider options={{ key, prepend }}>
      <ThemeProvider theme={dynamicMainTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
}
