import { atomWithLocalStorage } from '@/context/atoms/helper/atomWithLocalStorage';
import { type UserPermission } from '@ekkogmbh/apisdk/src/Sdk/ApiTypes';

export enum Permission {
  AREAS_READ = 'area_management.read',
  AREAS_WRITE = 'area_management.write',
  MAPPINGS_READ = 'mappings.read',
  MAPPINGS_WRITE = 'mappings.write',
  MAPPINGS_WRITE_RESTRICTED = 'mappings.write.restricted',
  OPERATION_GROUPS_READ = 'operation_groups.read',
  OPERATION_GROUPS_WRITE = 'operation_groups.write',
  PERMISSIONS_READ = 'permissions.read',
  PICKING_AREAS_READ = 'picking_areas.read',
  PICKING_AREAS_WRITE = 'picking_areas.write',
  PRESETS_WRITE = 'presets.write',
  ROLES_READ = 'roles.read',
  ROLES_WRITE = 'roles.write',
  SHELFLABELING_READ = 'shelf_labeling.read',
  SHELFLABELING_WRITE = 'shelf_labeling.write',
  SYSTEMSTATUS_READ = 'system_status.read',
  TEMPLATES_READ = 'templates.read',
  TEMPLATES_WRITE = 'templates.write',
  USERS_READ = 'users.read',
  USERS_WRITE = 'users.write',
  LINK_PROFILES_READ = 'link_profile.read',
  LINK_PROFILES_WRITE = 'link_profile.write',
  FINDER_READ = 'finder.read',
  FINDER_WRITE = 'finder.write',
  UPDATER_PROFILE_READ = 'updater_profile.read',
  UPDATER_PROFILE_WRITE = 'updater_profile.write',
  PICKER_PROFILES_READ = 'picker_profiles.read',
  PICKER_PROFILES_WRITE = 'picker_profiles.write',
  EKANBAN_READ = 'ekanban.read',
  EKANBAN_WRITE = 'ekanban.write',
}

export const hasRequiredPermissions = (actualPermissions: Permission[], requiredPermissions: Permission[]): boolean => {
  return requiredPermissions.every((permission) => actualPermissions.includes(permission));
};

export const anyNodePermissions = (permissions: UserPermission[]): string[] => {
  return permissions.reduce((acc: string[], curr: UserPermission) => {
    if (!acc.includes(curr.permissionName)) {
      acc.push(curr.permissionName);
    }

    return acc;
  }, []);
};

export const userPermissionsAtom = atomWithLocalStorage<Permission[]>('EKKO_WORKERUI_ANYNODEPERMISSIONS', []);
