'use client';
import { themeModeAtom } from '@/context/atoms/themeMode';
import { greyTheme, mainTheme } from '@/styles/theme';
import { createTheme, type ThemeOptions } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { type Theme } from '@mui/system';
import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';

export interface UseDynamicTheme {
  dynamicMainTheme: Theme;
  dynamicGreyTheme: Theme;
  forceDarkTheme: Theme;
}

export default function useDynamicTheme(): UseDynamicTheme {
  const [themeMode] = useAtom(themeModeAtom);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const createDynamicTheme = useCallback(
    (theme: ThemeOptions, forceDark?: boolean): Theme => {
      theme.palette === undefined && (theme.palette = {});
      const mode =
        forceDark === true ? 'dark' : themeMode === 'system' ? (prefersDarkMode ? 'dark' : 'light') : themeMode;
      theme.palette.mode = mode;

      theme.components = {
        ...theme.components,
        MuiTextField: {
          defaultProps: {
            color: mode === 'dark' ? 'secondary' : 'primary',
          },
        },
      };

      return createTheme({
        ...theme,
      });
    },
    [prefersDarkMode, themeMode],
  );

  const dynamicMainTheme = useMemo(() => createDynamicTheme(mainTheme), [createDynamicTheme]);
  const dynamicGreyTheme = useMemo(() => createDynamicTheme(greyTheme), [createDynamicTheme]);
  const forceDarkTheme = useMemo(() => createDynamicTheme(mainTheme, true), [createDynamicTheme]);

  return { dynamicMainTheme, dynamicGreyTheme, forceDarkTheme };
}
