import { hellix } from '@/styles/fonts/hellix';
import { type ThemeOptions } from '@mui/material/styles';

const fontFamily = [
  hellix.style.fontFamily,
  'Hellix',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export const typography: ThemeOptions['typography'] = {
  fontFamily,
  fontSize: 14,
};
