'use client';
import { ProGloveProvider } from '@/components/proglove/ProgloveProvider';
import { WebSocketProvider } from '@/components/websocket/WebSocketProvider';
import React from 'react';

// @TODO find a better name for this provider?
export default function ExternalScannerProvider({ children }: { children: React.ReactNode }): React.JSX.Element {
  // @TODO use proglove hostname and port from environment
  return (
    <WebSocketProvider hostname={'localhost'} port={9998}>
      <ProGloveProvider>{children}</ProGloveProvider>
    </WebSocketProvider>
  );
}
