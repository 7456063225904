import { type Atom, atom, type Getter } from 'jotai';

export const singletonAtom = <T>(getter: (get: Getter) => T): Atom<T> => {
  let singletonCache: T | undefined;
  return atom<T>((get: Getter) => {
    if (singletonCache === undefined) {
      singletonCache = getter(get);
      if (singletonCache === undefined) {
        throw new Error('Could not initialize singletonAtom via provided getter constructor');
      }
    }

    return singletonCache;
  });
};
