// @TODO copypasta, refactor

interface UseFetch {
  get: <T extends Record<string, any>>(url: string, body?: any) => Promise<T>;
  post: <T extends Record<string, any>>(url: string, body?: any) => Promise<T>;
  put: <T extends Record<string, any>>(url: string, body?: any) => Promise<T>;
  delete: <T extends Record<string, any>>(url: string, body?: any) => Promise<T>;
}

export function useFetch(): UseFetch {
  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
  };

  function request(method: string) {
    return async (url: string, body?: any) => {
      const requestOptions: RequestInit = {
        method,
      };
      if (body !== undefined) {
        requestOptions.headers = { 'Content-Type': 'application/json' };
        requestOptions.body = JSON.stringify(body);
      }
      const response = await fetch(url, requestOptions);
      return await handleResponse(response);
    };
  }

  // helper functions

  async function handleResponse(response: Response): Promise<any> {
    const isJson = response.headers?.get('content-type')?.includes('application/json');
    const data = isJson !== undefined ? await response.json() : null;

    // check for error response
    if (!response.ok) {
      // get error message from body or default to response status
      const error = data !== null ? data.message : response.statusText;
      return await Promise.reject(error);
    }

    return data;
  }
}
