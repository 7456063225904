import { TOOLBAR_HEIGHT_XS } from '@/styles/constants';
import { type ThemeOptions } from '@mui/material/styles';

export const components: ThemeOptions['components'] = {
  MuiToolbar: {
    defaultProps: {
      sx: (theme) => ({
        background: theme.palette.primary.main,
      }),
    },
    styleOverrides: {
      root: ({ theme }) => ({
        [theme.breakpoints.between('xs', 'sm')]: {
          [`@media (orientation: landscape)`]: {
            minHeight: TOOLBAR_HEIGHT_XS,
          },
        },
      }),
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        wordBreak: 'break-all',
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backdropFilter: 'blur(4px)',
      },
    },
  },
  MuiSvgIcon: {
    variants: [
      {
        props: { fontSize: 'ekkoLogoXL' },
        style: {
          fontSize: 192,
        },
      },
      {
        props: { fontSize: 'ekkoLogoXS' },
        style: {
          fontSize: 86,
        },
      },
    ],
  },
};
