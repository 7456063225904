import { type ThemeOptions } from '@mui/material/styles';

export const mainPalette: ThemeOptions['palette'] = {
  primary: {
    main: '#434343',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#5fd362',
    contrastText: '#ffffff',
  },
  error: {
    main: '#e83c40',
  },
  success: {
    main: '#5fd362',
  },
};

export const greyPalette: ThemeOptions['palette'] = {
  primary: {
    main: '#d4d4d4',
    contrastText: '#909090',
  },
  secondary: {
    main: '#909090',
    contrastText: '#d4d4d4',
  },
};
