import { grey } from '@mui/material/colors';
import { type ThemeOptions } from '@mui/material/styles';

// @see /src/styles/moduleOverrides/mui-material-styles
export const picker: ThemeOptions['picker'] = {
  border: {
    main: grey[600],
    dark: grey[800],
  },
  labelText: {
    main: grey[600],
    dark: grey[100],
  },
};
