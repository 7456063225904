import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';

export default function Loading({ size = 100 }: { size?: number }): React.JSX.Element {
  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex' }}>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress color={'inherit'} size={size} />
          </div>
        </Box>
      </Container>
    </>
  );
}
