import { authenticationAtom } from '@/context/atoms/Authentication';
import { singletonAtom } from '@/context/atoms/helper/singletonAtom';
import { jwtAuthMechanismAtom } from '@/context/atoms/JWTAuthMechanism';
import { publicConfigAtom } from '@/context/atoms/publicConfig';
import { HttpApiSdk } from '@ekkogmbh/apisdk';

export const apiSdkAtom = singletonAtom<Promise<HttpApiSdk>>(async (get) => {
  const publicConfig = await get(publicConfigAtom);
  const authMechanism = await get(jwtAuthMechanismAtom);
  authMechanism.authentication = get(authenticationAtom);

  const api = new HttpApiSdk(publicConfig.env.BACKEND_URL, authMechanism, true);

  await api.isAuthenticated();

  return api;
});
